<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mt-10 mb-10" elevation="0">
          <v-data-table
            :headers="cencalProductheader"
            :items="orders"
            :search="searchseller"
            sort-by="id"
            class="elevation-1"
            style="background: #f5f5f5 elevation-1"
            :loading="loading"
            loading-text="Loading... Please wait"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:top>
              <v-toolbar elevation="2" style="background: #f5f5f5">
                <v-toolbar-title
                  style="margin-left: -14px"
                  class="mt-2 mb-2 ml-2"
                >
                  Cancelled Products
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="pr-5 pb-5 pt-5">
                  <v-text-field
                    v-model="searchseller"
                    append-icon="mdi-magnify"
                    label="Search"
                    filled
                    rounded
                    dense
                    hide-details
                  ></v-text-field>
                </div>

                <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
              </v-toolbar>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <v-row>
                <v-col cols="12" class="ml-10">
                  <v-card style="min-width: 100%" elevation="0">
                    <v-card-title>
                      <p>Mother Site Cancel Reason</p>
                    </v-card-title>
                    <v-card-text>
                      <p>
                        {{ item.cancellation_response }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.share="{ item }">
              <v-btn color="#002441" text small @click="Share(item)"
                >Share</v-btn
              >
            </template>

            <template v-slot:item.see="{ item }">
              <v-btn small text color="#002441" @click="seeoriginal(item)">
                See Original
              </v-btn>
            </template>
          </v-data-table>

          <!-- Mother Site Share for this product -->
          <v-dialog v-model="ShareProductmodel" width="800px">
            <v-card>
              <v-toolbar color="#002441" dark flat>
                <v-toolbar-title
                  >Product Name: {{ temp.product_data.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-divider></v-divider>
              <v-card-text class="pt-0">
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Price Assign For Share Product</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Quantity</th>
                        <th class="text-left">Status</th>
                        <th class="text-center">Purchase Price</th>
                        <th class="text-center">Selling Price</th>
                        <!-- <th class="text-left">Action</th> -->
                      </tr>
                    </thead>
                    <tbody align="center">
                      <tr v-for="(item, index) in temp.max_min" :key="index">
                        <td class="text-left">
                          <v-form>
                            <v-text-field
                              v-if="index"
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.quantity"
                            ></v-text-field>
                            <p v-else>1</p>
                          </v-form>
                        </td>
                        <td class="text-left">{{ item.status }}</td>

                        <td>
                          <p>{{ temp.purchase_price }}</p>

                          <!-- <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 99px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.purchase_price"
                            ></v-text-field>
                          </v-form> -->
                        </td>
                        <td>
                          <v-form>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-2"
                              flat
                              dense
                              v-model="item.selling_price"
                            ></v-text-field>
                          </v-form>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>

              <!-- <v-card-actions>
                <v-row style="max-width:100%;">
                  <v-col class="d-flex flex-row">
                    <p class="pt-5  font-weight-medium">MRP:</p>
                    <v-text-field
                      outlined
                      type="number"
  
                      class="mt-2"
                      flat
                      dense
                      v-model="mrp"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col  class="d-flex flex-row mt-2">
                    <v-btn elevation="0" color="red" @click="Cancelshare">
                      Cancel
                    </v-btn>
                    <v-btn
                      elevation="0"
                      color="#002441"
                      @click="SaveShare(temp)"
                    >
                      Share
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions> -->

              <v-row style="max-width: 100%">
                <v-col class="d-flex flex-row">
                  <p class="pt-5 px-5 font-weight-medium">MRP:</p>
                  <v-text-field
                    v-if="temp.max_min && temp.max_min.length > 0"
                    outlined
                    type="number"
                    class="mt-2"
                    flat
                    dense
                    v-model="temp.max_min[0].mrp"
                  ></v-text-field>
                </v-col>

                <v-col></v-col>

                <v-col class="d-flex flex-row mt-2">
                  <v-btn elevation="0" color="red" @click="Cancelshare">
                    Cancel
                  </v-btn>
                  <v-btn elevation="0" color="#002441" @click="SaveShare">
                    Share
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    searchseller: "",
    orders: [],
    singleExpand: false,
    expanded: [],
    cencalProductheader: [
      {
        text: "product ID",
        value: "product_data.id",
      },
      { text: "Specification ID", value: "id", sortable: false },
      { text: "Product Title", value: "product_data.title", sortable: false },
      { text: "Brand ", value: "product_data.brand", sortable: false },
      { text: "Color ", value: "color", sortable: false },
      { text: "Size", value: "size", sortable: false },
      // { text: "View", value: "actions", sortable: false },
      { text: "", value: "share", sortable: false },
      { text: "", value: "see", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    loading: true,
    ShareProductmodel: false,
    temp: {
      product_data: {},
      max_min: [],
    },
    mrp: "",
    // specprice: [
    //   {
    //     quantity: 1,
    //     status: "Single",
    //     purchase_price: "",
    //     selling_price: "",
    //   },
    //   {
    //     quantity: "",
    //     status: "Minimum",
    //     purchase_price: "",
    //     selling_price: "",
    //   },
    //   {
    //     quantity: "",
    //     status: "Maximum",
    //     purchase_price: "",
    //     selling_price: "",
    //   },
    // ],
  }),

  methods: {
    initialize() {
      axios
        .get("productdetails/cancelled_own_shared_products/")
        .then((response) => {
          this.orders = response.data.data;
          this.loading = false;
          // console.log("info", this.orders);
        });
    },
    Share(item) {
      this.ShareProductmodel = true;
      // console.log("this is item", item);
      this.temp = item;
    },
    Cancelshare() {
      this.ShareProductmodel = false;
    },
    SaveShare(temp) {
    
      this.temp.max_min.forEach((element) => {
        element.purchase_price = this.temp.purchase_price;
      });

      let datas = {
        MRP: this.temp.max_min[0].mrp,
        data_array: this.temp.max_min,
      };
      //  console.log("this is last", datas);


       axios
        .post(
          `productdetails/share_cancelled_product/${this.temp.id}/`,
          datas
        )
        .then((response) => {
          if (response.data.success) {
            this.ShareProductmodel = false;
            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            window.location.reload();
          } else {
            this.text = response.data.message;
            this.color = "red";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong !!";
          //this.text = response.data.message;
          this.color = "error";
          this.snackbar = true;
        });

    },
    seeoriginal(item)
    {
       let routeData = this.$router.resolve({
        name: `/dashboard/pages/specification/${item.id}`,
      });
      routeData.href = `/dashboard/pages/specification/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    }
     

  },

  mounted() {
    this.initialize();
  },
};
</script>

<style>
</style>